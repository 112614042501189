<template>
  <CommonLayout class="product-layout">
    <!-- <Balance :points="this.getProfile.coinBalance" /> -->
    <div class="product">
      <div class="product__container">
        <div class="product__header">
          <router-link
            class="button-default button-back product__back"
            tag="button"
            :to="{ name: 'store' }"
            ><span></span>Назад</router-link
          >
          <div class="product__group">
            <h1 class="title">
              Виртуальный магазин
              <div class="product-balance">
                <img
                  src="@/assets/img/pic2/coin.svg"
                  width="22"
                  height="22"
                  alt=""
                />
                GPB-coins:<b>{{ getProfile.coinBalance }}</b>
              </div>
            </h1>
            <router-link
              class="button button-default product-order"
              tag="button"
              :to="{ name: 'orders' }"
              ><span>Мои заказы</span></router-link
            >
          </div>
        </div>
        <Loading v-if="loading" position="center" theme="dark" />
        <div v-else class="product__content">
          <div class="product__image-wrap">
            <div
              class="product__image"
              v-if="getProduct.imgUrl"
              @click="$modal.show('photo', { src: image })"
            >
              <img :src="image" alt="" />
            </div>
            <div v-else class="product__image default">
              <img src="/static/img/photo-default.svg" alt="" />
            </div>
            <div class="product__price field-score field-score_long">
              <span>
                {{ COINS }}
                <span class="coin">
                  <img
                    src="@/assets/img/pic2/coin.svg"
                    width="15"
                    height="15"
                    alt=""
                  />
                </span>
                {{ getProduct.price | priceFilter }}
              </span>
            </div>
          </div>
          <div class="product__text-wrap">
            <div class="product__heading">
              <div class="product__heading-text">
                {{ getProduct.name }}
              </div>
            </div>
            <div class="product__amount">Доступно: {{ getProduct.amount }}</div>
            <div
              class="product__description"
              v-html="getProduct.description"
            ></div>
            <div class="product__actions">
              <div class="product__count">
                <button @click="icrement" class="button button-minus"></button>
                <div class="product__count-number">
                  <span>{{ count }}</span>
                </div>
                <button @click="decrement" class="button button-plus"></button>
              </div>
              <button
                :disabled="lock"
                class="product__button button button-default button_big"
                @click="addToCart(getProduct)"
              >
                Заказать
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
// import Balance from "@/components/DashboardBalance.vue";
import { getBaseURL } from "@/utils";
import { isCartFull } from "@/utils/store";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["id"],
  components: {
    CommonLayout,
    // Balance,
  },
  data() {
    return {
      buttonPressed: false,
      count: 1,
      lock: false,
    };
  },
  computed: {
    ...mapGetters([
      "getProductStatus",
      "getProduct",
      "getCart",
      "getProfile",
      "getGame",
    ]),
    loading() {
      return this.getProductStatus === "loading";
    },
    image() {
      return getBaseURL() + this.getProduct.imgUrl;
    },
  },
  created() {
    this.PRODUCT_FETCH(this.id);
  },
  methods: {
    ...mapActions([
      "CATALOG_FETCH",
      "PRODUCT_FETCH",
      "CART_ADD",
      "CART_CHECKOUT",
      "USER_FETCH",
      "USER_FETCH_NOTIFY",
    ]),
    icrement() {
      if (this.count > 1) {
        this.count--;
      }
      this.buttonPressed = false;
    },
    decrement() {
      if (this.getProduct.amount > this.count) {
        this.count++;
      }
      this.buttonPressed = false;
    },
    checkShopOpen() {
      if (this.getGame.currentWeek >= 2) {
        return true;
      }
      return false;
    },
    addToCart(product) {
      if (!this.checkShopOpen()) {
        this.$modal.show("message", {
          message: "Магазин откроется с 3 недели",
        });
        return;
      }
      if (product.amount <= 0) {
        this.$modal.show("message", {
          message: `Извините, на Обливии закончился трофей "${product.name}". Выберите другой подарок.`,
        });
        return;
      }

      // if (this.buttonPressed) {
      //   this.$router.push({ name: "cart" });
      //   return;
      // }

      this.$modal.show("confirm", {
        message: "Готовы подтвердить заказ?",
        cb: () => {
          if (isCartFull(product, this.count, this.getCart)) {
            this.CART_ADD({
              data: product,
              count: this.count,
            }).then(() => {
              this.lock = true;
              this.checkout();
            });
          }
        },
      });
    },
    checkout() {
      const {
        comment,
        getCart,
        getProfile: { id: userId },
      } = this;

      const orderDetails = getCart.map(({ id, prizeAmount, price }) => {
        return {
          prizeId: id,
          prizeAmount,
          prizePrice: price,
        };
      });

      this.CART_CHECKOUT({
        userId,
        orderDetails,
        comment,
      })
        .then(() => {
          this.$modal.show("message", {
            message: "Заказ успешно оформлен!",
          });
          this.USER_FETCH();
          this.$router.push({ name: "orders" });
          this.USER_FETCH_NOTIFY();
          this.lock = false;
          this.CATALOG_FETCH();
          setTimeout(() => {
            this.count = 1;
          }, 1000);
        })
        .catch((error) => {
          this.lock = false;
          this.handleError(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.product {
  &__container {
    position: relative;
    z-index: 2;
    width: 1230px;
    margin: 0 auto;
  }
  &__header {
    position: relative;
    margin-bottom: 40px;
    font-size: 54px;
    color: #5cffed;
  }
  &__back {
    position: absolute;
    right: 100%;
    top: 16px;
    margin-right: 20px;
    @media (max-width: 1700px) {
      display: none;
    }
  }
  &__title {
    font-weight: normal;
    font-size: 40px;
  }
  &__content {
    position: relative;
    display: flex;
    padding: 45px 55px;
    padding-bottom: 60px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 300px;
      background-image: url(~@/assets/img/pic2/border-page.svg);
      background-repeat: no-repeat;
      background-size: 100% auto;
      content: "";
      z-index: -1;
    }
    &::after {
      position: absolute;
      top: 300px;
      left: 0;
      width: 100%;
      height: calc(100% - 300px);
      background-image: url(~@/assets/img/pic2/border-page.svg);
      background-repeat: no-repeat;
      background-size: 100% auto;
      background-position: 0 100%;
      content: "";
      z-index: -1;
    }
  }
  &__image-wrap {
    flex: 0 0 auto;
    width: 300px;
    margin-right: 60px;
  }
  &__image {
    @include clickable();
    margin-bottom: 32px;
    &.default {
      cursor: default;
    }
    img {
      @include responsive-img();
      display: block;
    }
    position: relative;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='274' viewBox='0 0 24 274' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 274h24v-9H8.2808V10H24V0H0v274z' fill='%230FF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
    }
    &::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='274' viewBox='0 0 24 274' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 274h24v-9H8.2808V10H24V0H0v274z' fill='%230FF'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: auto 100%;
      content: "";
      transform: rotate(180deg);
    }
  }
  &__group {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__price {
    margin: 0 auto;
  }
  &__text-wrap {
    flex: 1 1 auto;
    width: 1px;
  }
  &__heading {
    font-family: $fontRafale;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    word-break: break-word;
  }
  &__amount {
    font-family: $fontRafale;
    font-size: 16px;
    color: #75b5b8;
    margin-top: 30px;
  }
  &__description {
    margin-top: 30px;
    font-family: $fontRafale;
    font-size: 18px;
    color: #c3ebeb;
    word-break: break-word;
    p {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
  &__actions {
    margin-top: 40px;
    display: flex;
    align-items: center;
  }
  &__count {
    display: flex;
    align-items: center;
    width: 190px;
    justify-content: space-between;
    margin-right: 50px;
    .button-minus {
    }
    &-number {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      color: #5effff;
      margin-bottom: 4px;
    }
    .button-plus {
    }
  }
  &__button {
    flex: 0 0 auto;
    min-width: 250px;
  }
  &-order {
    margin-left: 20px;
    position: relative;
    margin-bottom: -18px;
  }
  &-balance {
    height: 45px;
    margin-left: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 32px;
    color: #fff;
    background-image: url(~@/assets/img/icon/bracket.svg);
    background-repeat: no-repeat;
    position: relative;
    padding: 0 15px;
    img {
      margin-right: 4px;
      display: block;
      margin-bottom: -6px;
    }
    b {
      font-weight: 700;
      font-size: 32px;
      color: #00ffff;
      margin-left: 8px;
    }
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      height: 45px;
      width: 12px;
      background-image: url(~@/assets/img/icon/bracket.svg);
      background-repeat: no-repeat;
      transform: rotate(180deg);
      content: "";
    }
  }
}

.coin {
  margin-top: 4px;
  margin-left: 5px;
  margin-right: 4px;
  img {
    display: block;
    margin-left: 10px;
  }
}

@media (max-width: 1750px) {
  .product__decor {
    right: 15px;
  }
}
</style>
